const state = reactive({

    settings: <any>[],

})

export function useSettings() {

    async function fetchSettings() {

        const route = useRoute()
        const config = useRuntimeConfig()
        const cv = config.public.cv
        const version = route.query?._storyblok ? 'draft' : 'draft'

        const story = await useAsyncStoryblok(
            'settings',
            { 
                version: version,
                cv: Number(cv),
                resolve_relations: [] 
            },
            { 
                resolveRelations: [], 
                resolveLinks: 'url' 
            }
        )
    
        if (story.value.status) {
            throw createError({
                statusCode: story.value.status,
                statusMessage: story.value.response
            })
        }

        state.settings = story.value.content

    }


    return {
        ...toRefs(state),
        fetchSettings,
    }
}